import { Component, input, model, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqDto } from '@api-client';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-faq-item',
  standalone: true,
  imports: [CommonModule, IconComponent],

  template: `
    @if (faq(); as faq) {
    <div [ngClass]="{ 'bg-neutral-100 rounded-md ': open() }" class="px-4 py-5">
      <section class="flex gap-2 items-start">
        <span class="text-xl font-extrabold text-primary">Q</span>
        <p class="mt-1">{{ faq.question }}</p>
      </section>

      @if (open()) {
      <section class="flex gap-2 items-start">
        <span class="text-xl font-extrabold text-neutral-500">A</span>
        <p class="max-w-full break-words mt-1">{{ faq.answer }}</p>
      </section>
      }
    </div>
    }
  `,
  styles: `
    :host {
      @apply flex flex-col gap-3;
    }
  `,
})
export class FaqItemComponent {
  faq = input.required<FaqDto>();

  open = model<boolean>(false);
}
