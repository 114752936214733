/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CkEditorFileDto } from '../models/ck-editor-file-dto';
import { FileDto } from '../models/file-dto';
import { storageControllerDeleteFile } from '../fn/storage/storage-controller-delete-file';
import { StorageControllerDeleteFile$Params } from '../fn/storage/storage-controller-delete-file';
import { storageControllerUploadFiles } from '../fn/storage/storage-controller-upload-files';
import { StorageControllerUploadFiles$Params } from '../fn/storage/storage-controller-upload-files';
import { storageControllerUploadImage } from '../fn/storage/storage-controller-upload-image';
import { StorageControllerUploadImage$Params } from '../fn/storage/storage-controller-upload-image';

@Injectable({ providedIn: 'root' })
export class StorageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `storageControllerUploadImage()` */
  static readonly StorageControllerUploadImagePath = '/api/storage/ckeditor';

  /**
   * 이미지 업로드.
   *
   * 이미지를 업로드합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerUploadImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadImage$Response(params: StorageControllerUploadImage$Params, context?: HttpContext): Observable<StrictHttpResponse<CkEditorFileDto>> {
    return storageControllerUploadImage(this.http, this.rootUrl, params, context);
  }

  /**
   * 이미지 업로드.
   *
   * 이미지를 업로드합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerUploadImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadImage(params: StorageControllerUploadImage$Params, context?: HttpContext): Observable<CkEditorFileDto> {
    return this.storageControllerUploadImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<CkEditorFileDto>): CkEditorFileDto => r.body)
    );
  }

  /** Path part for operation `storageControllerUploadFiles()` */
  static readonly StorageControllerUploadFilesPath = '/api/storage/{bucketName}';

  /**
   * 파일 업로드.
   *
   * 파일을 업로드합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerUploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadFiles$Response(params: StorageControllerUploadFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileDto>>> {
    return storageControllerUploadFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * 파일 업로드.
   *
   * 파일을 업로드합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerUploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  storageControllerUploadFiles(params: StorageControllerUploadFiles$Params, context?: HttpContext): Observable<Array<FileDto>> {
    return this.storageControllerUploadFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileDto>>): Array<FileDto> => r.body)
    );
  }

  /** Path part for operation `storageControllerDeleteFile()` */
  static readonly StorageControllerDeleteFilePath = '/api/storage';

  /**
   * 파일 삭제.
   *
   * 파일을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageControllerDeleteFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageControllerDeleteFile$Response(params: StorageControllerDeleteFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageControllerDeleteFile(this.http, this.rootUrl, params, context);
  }

  /**
   * 파일 삭제.
   *
   * 파일을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageControllerDeleteFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageControllerDeleteFile(params: StorageControllerDeleteFile$Params, context?: HttpContext): Observable<void> {
    return this.storageControllerDeleteFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
