import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { AdminService, AttractionService, CoBuyingDto, CoBuyingService, MemberService, ProductDto, ProductService } from '@api-client';
import { lastValueFrom } from 'rxjs';
import dayjs from 'dayjs';

export class CustomValidators {
  static url(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (!value) return null;

    const pattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    return pattern.test(value) ? null : { url: true };
  }

  /**
   * 관광지 이름의 중복 여부를 검사하고, 중복되는 경우 유효성 검사 오류를 반환합니다.
   * @param service
   * @returns
   * @author 최강훈 <ganghun@lepisode.team>
   */
  static attractionName(service: AttractionService): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.attractionControllerFindByName({ name: value }));

      return check ? { attractionName: true } : null;
    };
  }

  /**
   * 상품 이름의 중복 여부를 검사하고, 중복되는 경우 유효성 검사 오류를 반환합니다.
   * @param service
   * @returns
   */
  static productName(service: ProductService, exclude?: ProductDto): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.productControllerFindByName({ name: value }));

      if (exclude) {
        return check && check.id !== exclude.id ? { productName: true } : null;
      }

      return check ? { productName: true } : null;
    };
  }

  static adminName(service: AdminService): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.adminControllerFindByUsername({ username: value }));

      return check ? { adminName: true } : null;
    };
  }

  static adminCode(service: AdminService): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.adminControllerFindByCode({ code: value }));

      return check ? { adminCode: true } : null;
    };
  }

  static memberEmail(service: MemberService): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.memberControllerFindByEmail({ email: value }));

      return check && !check.temporary ? { memberEmail: true } : null;
    };
  }

  static memberTel(service: MemberService): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.memberControllerFindByTel({ tel: value }));

      return check && !check.temporary ? { memberTel: true } : null;
    };
  }

  static coBuyingName(service: CoBuyingService, exclude?: CoBuyingDto): AsyncValidatorFn {
    return async (control: AbstractControl) => {
      const value = control.value;
      const check = await lastValueFrom(service.coBuyingControllerFindByName({ name: value }));

      if (exclude) {
        return check && check.id !== exclude.id ? { productName: true } : null;
      }

      return check ? { coBuyingName: true } : null;
    };
  }

  /**
   * 입력한 날짜가 현재 날짜 이후인지 검사하고, 그렇지 않은 경우 유효성 검사 오류를 반환합니다.
   * @param index
   * @returns
   */
  static isAfter(index: string | Date): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;

      const _index = typeof index === 'string' ? new Date(index) : index;

      return dayjs(control.value).isAfter(_index) ? null : { isAfter: true };
    };
  }
}
