import { Component, input } from '@angular/core';

@Component({
  selector: 'app-text-field',
  standalone: true,
  template: `
    <div class="flex flex-col gap-1 text-neutral-900">
      <label class="text-sm font-bold text-neutral-700">{{ label() }}</label>
      <ng-content></ng-content>
    </div>
  `,
})
export class TextFieldComponent {
  label = input.required<string>();
}
