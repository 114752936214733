import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, StaticProvider, signal } from '@angular/core';

export type ModalConfig = {
  componentProps?: Record<string, any>;
  hasBackdrop?: boolean;
  disableClose?: boolean;
  providers?: StaticProvider[];
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modals = signal<ComponentType<any>[]>([]);

  currentOpenModal: DialogRef<any, ComponentType<any>> | null = null;

  constructor(private readonly dialog: Dialog) {}

  public open<T = any>(component: ComponentType<any>): DialogRef<T, ComponentType<any>>;
  public open<T = any>(component: ComponentType<any>, config: ModalConfig): DialogRef<T, ComponentType<any>>;

  public open<T = any>(component: ComponentType<any>, config?: ModalConfig): DialogRef<T, ComponentType<any>> {
    if (this.modals().includes(component)) {
      return this.currentOpenModal as DialogRef<T, ComponentType<any>>;
    }

    this.currentOpenModal = this.dialog.open<T, unknown, ComponentType<any>>(component, {
      data: config?.componentProps,
      hasBackdrop: config?.hasBackdrop ?? true,
      disableClose: config?.disableClose ?? false,
      providers: config?.providers,
    });

    this.modals.update((modals) => [...modals, component]);

    this.currentOpenModal.closed.subscribe({
      next: () => {
        this.modals.update((modals) => modals.filter((modal) => modal !== component));
        this.currentOpenModal = null;
      },
    });

    return this.currentOpenModal;
  }

  close() {
    if (this.currentOpenModal) {
      this.currentOpenModal.close();
      this.modals.update((modals) => modals.filter((modal) => modal !== this.currentOpenModal?.componentInstance));
      this.currentOpenModal = null;
    }
  }
}
