import { Component, computed, ElementRef, input, viewChild } from '@angular/core';
import { BaseInputComponent } from '../common/base-input.component';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';

@Component({
  selector: 'app-input-date',
  standalone: true,
  imports: [FormsModule, DatePipe],
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <input
          #inputRef
          class="w-full"
          type="date"
          [ngModel]="inputValue()"
          (ngModelChange)="handleModelChange($event)"
          [min]="min() | date : 'y-MM-dd'"
          [max]="max() | date : 'y-MM-dd'"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
          [autofocus]="autofocus()"
        />
      </div>
    </label>
  `,
  styleUrl: '../common/input.common.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDateComponent,
      multi: true,
    },
  ],
})
export class InputDateComponent extends BaseInputComponent<Date> {
  min = input<string | Date>();
  max = input<string | Date>();

  inputValue = computed(() => dayjs(this.value()).format('YYYY-MM-DD'));

  handleModelChange(value: string) {
    this.value.set(dayjs(value).toDate());
  }
}
