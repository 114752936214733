@if (coupon(); as coupon) {
<div class="flex flex-col gap-2 overflow-hidden border rounded-md shadow-lg border-neutral-200 size-full">
  <div class="flex flex-col gap-4 p-4">
    <div class="flex items-center justify-between">
      <h2>{{ coupon.coupon.name }}</h2>
      @switch (coupon.coupon.type) { @case ('FIXED') {
      <h3>{{ $any(coupon.coupon.detail).amount | number }}원 할인</h3>
      } @case ('RATIONAL') {
      <div class="flex flex-col gap-1">
        <h3>{{ $any(coupon.coupon.detail).ratio }}% 할인</h3>
        <p>최대 {{ $any(coupon.coupon.detail).max }}원 할인</p>
      </div>
      } @case ('REDEEM') {
      <h3>{{ $any(coupon.coupon.detail).product }} 증정</h3>
      } }
    </div>
  </div>

  @if (button()) { @if (coupon.used) {
  <p class="py-2 text-center bg-neutral-100 text-neutral-400">사용 완료 : {{ coupon.usedAt | date : 'y-MM-dd HH:mm:ss' }}</p>
  } @else {
  <button (click)="use.emit()" class="py-2 text-lg font-bold text-center text-white bg-secondary">사용하기</button>
  } }
</div>
}
