/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AttractionDto } from '../../models/attraction-dto';
import { CursorPaginationDto } from '../../models/cursor-pagination-dto';

export interface AttractionControllerSearchCursor$Params {

/**
 * 페이지 크기
 */
  pageSize: number;

/**
 * 정렬 기준
 */
  orderBy?: string;

/**
 * 정렬 방향
 */
  align?: 'asc' | 'desc';

/**
 * 검색어
 */
  query?: string;

/**
 * 커서
 */
  cursor?: string;

/**
 * 위도
 */
  lat?: number;

/**
 * 경도
 */
  lng?: number;

/**
 * 카테고리
 */
  category?: string;
}

export function attractionControllerSearchCursor(http: HttpClient, rootUrl: string, params: AttractionControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<AttractionDto>;
}>> {
  const rb = new RequestBuilder(rootUrl, attractionControllerSearchCursor.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('align', params.align, {});
    rb.query('query', params.query, {});
    rb.query('cursor', params.cursor, {});
    rb.query('lat', params.lat, {});
    rb.query('lng', params.lng, {});
    rb.query('category', params.category, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CursorPaginationDto & {
      'items'?: Array<AttractionDto>;
      }>;
    })
  );
}

attractionControllerSearchCursor.PATH = '/api/attraction/search/cursor';
