import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberString',
  pure: true,
  standalone: true,
})
export class NumberStringPipe implements PipeTransform {
  transform(value?: number): string {
    if (!value) return '0';
    if (Number.isNaN(value)) return '0';

    const number = value.toString();

    const result: string[] = [`${this.formatNumber(number.slice(-4))}`];

    if (number.length > 4) {
      const formatted = this.formatNumber(number.slice(-8, -4));
      if (formatted) result.push(`${formatted}만`);
    }

    if (number.length > 8) {
      const formatted = this.formatNumber(number.slice(-12, -8));
      if (formatted) result.push(`${formatted}억`);
    }

    if (number.length > 12) {
      const formatted = this.formatNumber(number.slice(-16, -12));
      if (formatted) result.push(`${formatted}조`);
    }

    if (number.length > 16) {
      const formatted = this.formatNumber(number.slice(-20, -16));
      if (formatted) result.push(`${formatted}경`);
    }

    return result.reverse().join(' ');
  }

  private formatNumber(value: string): string {
    if (value === '0000') return '';
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
