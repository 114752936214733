import { Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonIcon, IonSpinner, IonSkeletonText } from '@ionic/angular/standalone';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [IonSkeletonText, IonSpinner, IonIcon, CommonModule],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
    styles: `
      :host {
        @apply block relative size-full overflow-hidden;
      }
    `,
})
export class ImageComponent {
  src = input.required<string>();

  loaded = signal(false);
  error = signal(false);

  onLoad() {
    this.loaded.set(true);
  }
}
