import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { booleanAttribute, Component, input, output } from '@angular/core';
import { CouponLogDto } from '@api-client';

@Component({
  selector: 'app-coupon-log-item',
  standalone: true,
  imports: [DecimalPipe, DatePipe, NgClass],
  templateUrl: './coupon-log-item.component.html',
  styleUrl: './coupon-log-item.component.scss',
})
export class CouponLogItemComponent {
  use = output<void>();

  coupon = input.required<CouponLogDto>();
  button = input<boolean, string | boolean>(true, { transform: booleanAttribute });
}
