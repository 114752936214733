import { Component, effect, input, output } from '@angular/core';

@Component({
  selector: 'app-naver-map-marker',
  standalone: true,
  templateUrl: './naver-map-marker.component.html',
  styleUrl: './naver-map-marker.component.scss',
})
export class NaverMapMarkerComponent {
  changed = output<void>();

  lat = input.required<number>();
  lng = input.required<number>();

  constructor() {
    effect(() => {
      this.lat();
      this.lng();
      this.changed.emit();
    });
  }
}
