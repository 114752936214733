import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dataUrl',
  standalone: true,
})
export class DataUrlPipe implements PipeTransform {
  transform(file: File | string) {
    return file instanceof File ? URL.createObjectURL(file) : file;
  }
}
