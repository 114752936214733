/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CountDto } from '../models/count-dto';
import { statControllerGetStat } from '../fn/stat/stat-controller-get-stat';
import { StatControllerGetStat$Params } from '../fn/stat/stat-controller-get-stat';

@Injectable({ providedIn: 'root' })
export class StatService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `statControllerGetStat()` */
  static readonly StatControllerGetStatPath = '/api/stat/{model}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statControllerGetStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  statControllerGetStat$Response(params: StatControllerGetStat$Params, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return statControllerGetStat(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statControllerGetStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statControllerGetStat(params: StatControllerGetStat$Params, context?: HttpContext): Observable<CountDto> {
    return this.statControllerGetStat$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

}
