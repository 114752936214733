/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { logControllerFindAll } from '../fn/log/log-controller-find-all';
import { LogControllerFindAll$Params } from '../fn/log/log-controller-find-all';
import { logControllerFindByType } from '../fn/log/log-controller-find-by-type';
import { LogControllerFindByType$Params } from '../fn/log/log-controller-find-by-type';
import { LogDto } from '../models/log-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class LogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `logControllerFindAll()` */
  static readonly LogControllerFindAllPath = '/api/log';

  /**
   * 로그 전체 조회.
   *
   * 전체 로그를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  logControllerFindAll$Response(params?: LogControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LogDto>>> {
    return logControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그 전체 조회.
   *
   * 전체 로그를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logControllerFindAll(params?: LogControllerFindAll$Params, context?: HttpContext): Observable<Array<LogDto>> {
    return this.logControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>): Array<LogDto> => r.body)
    );
  }

  /** Path part for operation `logControllerFindByType()` */
  static readonly LogControllerFindByTypePath = '/api/log/{type}';

  /**
   * 로그 검색.
   *
   * 특정 타입의 로그를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logControllerFindByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  logControllerFindByType$Response(params: LogControllerFindByType$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<LogDto>;
}>> {
    return logControllerFindByType(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그 검색.
   *
   * 특정 타입의 로그를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logControllerFindByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logControllerFindByType(params: LogControllerFindByType$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<LogDto>;
}> {
    return this.logControllerFindByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<LogDto>;
}>): OffsetPaginationDto & {
'items'?: Array<LogDto>;
} => r.body)
    );
  }

}
