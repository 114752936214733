/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { orderControllerCancel } from '../fn/order/order-controller-cancel';
import { OrderControllerCancel$Params } from '../fn/order/order-controller-cancel';
import { orderControllerCreate } from '../fn/order/order-controller-create';
import { OrderControllerCreate$Params } from '../fn/order/order-controller-create';
import { orderControllerDelivered } from '../fn/order/order-controller-delivered';
import { OrderControllerDelivered$Params } from '../fn/order/order-controller-delivered';
import { orderControllerFindById } from '../fn/order/order-controller-find-by-id';
import { OrderControllerFindById$Params } from '../fn/order/order-controller-find-by-id';
import { orderControllerOnDelivery } from '../fn/order/order-controller-on-delivery';
import { OrderControllerOnDelivery$Params } from '../fn/order/order-controller-on-delivery';

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `orderControllerFindById()` */
  static readonly OrderControllerFindByIdPath = '/api/order/{id}';

  /**
   * 주문 조회.
   *
   * 주문 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerFindById$Response(params: OrderControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 주문 조회.
   *
   * 주문 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerFindById(params: OrderControllerFindById$Params, context?: HttpContext): Observable<void> {
    return this.orderControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `orderControllerCreate()` */
  static readonly OrderControllerCreatePath = '/api/order';

  /**
   * 주문 등록.
   *
   * 새 주문을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderControllerCreate$Response(params: OrderControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 주문 등록.
   *
   * 새 주문을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderControllerCreate(params: OrderControllerCreate$Params, context?: HttpContext): Observable<void> {
    return this.orderControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `orderControllerOnDelivery()` */
  static readonly OrderControllerOnDeliveryPath = '/api/order/{id}/on-delivery';

  /**
   * 배송 시작.
   *
   * 주문의 배송을 시작합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerOnDelivery()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerOnDelivery$Response(params: OrderControllerOnDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerOnDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송 시작.
   *
   * 주문의 배송을 시작합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerOnDelivery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerOnDelivery(params: OrderControllerOnDelivery$Params, context?: HttpContext): Observable<void> {
    return this.orderControllerOnDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `orderControllerDelivered()` */
  static readonly OrderControllerDeliveredPath = '/api/order/{id}/delivered';

  /**
   * 배송 완료.
   *
   * 주문의 배송을 완료합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerDelivered()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerDelivered$Response(params: OrderControllerDelivered$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerDelivered(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송 완료.
   *
   * 주문의 배송을 완료합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerDelivered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerDelivered(params: OrderControllerDelivered$Params, context?: HttpContext): Observable<void> {
    return this.orderControllerDelivered$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `orderControllerCancel()` */
  static readonly OrderControllerCancelPath = '/api/order/{id}/cancel';

  /**
   * 주문 취소.
   *
   * 주문을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerCancel$Response(params: OrderControllerCancel$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerCancel(this.http, this.rootUrl, params, context);
  }

  /**
   * 주문 취소.
   *
   * 주문을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderControllerCancel(params: OrderControllerCancel$Params, context?: HttpContext): Observable<void> {
    return this.orderControllerCancel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
