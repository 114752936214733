/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { couponLogControllerFindByMemberId } from '../fn/coupon-log/coupon-log-controller-find-by-member-id';
import { CouponLogControllerFindByMemberId$Params } from '../fn/coupon-log/coupon-log-controller-find-by-member-id';
import { couponLogControllerGetMy } from '../fn/coupon-log/coupon-log-controller-get-my';
import { CouponLogControllerGetMy$Params } from '../fn/coupon-log/coupon-log-controller-get-my';
import { couponLogControllerGive } from '../fn/coupon-log/coupon-log-controller-give';
import { CouponLogControllerGive$Params } from '../fn/coupon-log/coupon-log-controller-give';
import { couponLogControllerUse } from '../fn/coupon-log/coupon-log-controller-use';
import { CouponLogControllerUse$Params } from '../fn/coupon-log/coupon-log-controller-use';
import { CouponLogDto } from '../models/coupon-log-dto';

@Injectable({ providedIn: 'root' })
export class CouponLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `couponLogControllerGetMy()` */
  static readonly CouponLogControllerGetMyPath = '/api/coupon-log';

  /**
   * 내 쿠폰 내역 조회.
   *
   * 로그인한 회원의 쿠폰 내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponLogControllerGetMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerGetMy$Response(params?: CouponLogControllerGetMy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CouponLogDto>>> {
    return couponLogControllerGetMy(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 쿠폰 내역 조회.
   *
   * 로그인한 회원의 쿠폰 내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponLogControllerGetMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerGetMy(params?: CouponLogControllerGetMy$Params, context?: HttpContext): Observable<Array<CouponLogDto>> {
    return this.couponLogControllerGetMy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CouponLogDto>>): Array<CouponLogDto> => r.body)
    );
  }

  /** Path part for operation `couponLogControllerFindByMemberId()` */
  static readonly CouponLogControllerFindByMemberIdPath = '/api/coupon-log/member/{memberId}';

  /**
   * 회원 쿠폰 내역 조회.
   *
   * 회원의 쿠폰 내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponLogControllerFindByMemberId()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerFindByMemberId$Response(params: CouponLogControllerFindByMemberId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CouponLogDto>>> {
    return couponLogControllerFindByMemberId(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 쿠폰 내역 조회.
   *
   * 회원의 쿠폰 내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponLogControllerFindByMemberId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerFindByMemberId(params: CouponLogControllerFindByMemberId$Params, context?: HttpContext): Observable<Array<CouponLogDto>> {
    return this.couponLogControllerFindByMemberId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CouponLogDto>>): Array<CouponLogDto> => r.body)
    );
  }

  /** Path part for operation `couponLogControllerGive()` */
  static readonly CouponLogControllerGivePath = '/api/coupon-log/{memberId}';

  /**
   * 쿠폰 지급.
   *
   * 회원에게 쿠폰을 지급합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponLogControllerGive()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerGive$Response(params: CouponLogControllerGive$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponLogDto>> {
    return couponLogControllerGive(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 지급.
   *
   * 회원에게 쿠폰을 지급합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponLogControllerGive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerGive(params: CouponLogControllerGive$Params, context?: HttpContext): Observable<CouponLogDto> {
    return this.couponLogControllerGive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponLogDto>): CouponLogDto => r.body)
    );
  }

  /** Path part for operation `couponLogControllerUse()` */
  static readonly CouponLogControllerUsePath = '/api/coupon-log/{couponLogId}/use';

  /**
   * 쿠폰 사용.
   *
   * 쿠폰을 사용합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponLogControllerUse()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerUse$Response(params: CouponLogControllerUse$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponLogDto>> {
    return couponLogControllerUse(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 사용.
   *
   * 쿠폰을 사용합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponLogControllerUse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponLogControllerUse(params: CouponLogControllerUse$Params, context?: HttpContext): Observable<CouponLogDto> {
    return this.couponLogControllerUse$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponLogDto>): CouponLogDto => r.body)
    );
  }

}
