/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateReservationDto } from '../models/create-reservation-dto';
import { reservationControllerCall } from '../fn/reservation/reservation-controller-call';
import { ReservationControllerCall$Params } from '../fn/reservation/reservation-controller-call';
import { reservationControllerCancel } from '../fn/reservation/reservation-controller-cancel';
import { ReservationControllerCancel$Params } from '../fn/reservation/reservation-controller-cancel';
import { reservationControllerCreate } from '../fn/reservation/reservation-controller-create';
import { ReservationControllerCreate$Params } from '../fn/reservation/reservation-controller-create';
import { reservationControllerSeat } from '../fn/reservation/reservation-controller-seat';
import { ReservationControllerSeat$Params } from '../fn/reservation/reservation-controller-seat';
import { ReservationDto } from '../models/reservation-dto';

@Injectable({ providedIn: 'root' })
export class ReservationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reservationControllerCreate()` */
  static readonly ReservationControllerCreatePath = '/api/reservation';

  /**
   * 예약 등록.
   *
   * 새로운 예약을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerCreate$Response(params: ReservationControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateReservationDto>> {
    return reservationControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 등록.
   *
   * 새로운 예약을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerCreate(params: ReservationControllerCreate$Params, context?: HttpContext): Observable<CreateReservationDto> {
    return this.reservationControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateReservationDto>): CreateReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerSeat()` */
  static readonly ReservationControllerSeatPath = '/api/reservation/{id}/seat';

  /**
   * 예약 완료.
   *
   * 예약을 완료합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerSeat()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSeat$Response(params: ReservationControllerSeat$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerSeat(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 완료.
   *
   * 예약을 완료합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerSeat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSeat(params: ReservationControllerSeat$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerSeat$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerCall()` */
  static readonly ReservationControllerCallPath = '/api/reservation/{id}/call';

  /**
   * 예약 호출.
   *
   * 예약을 호출합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCall()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCall$Response(params: ReservationControllerCall$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerCall(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 호출.
   *
   * 예약을 호출합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCall$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCall(params: ReservationControllerCall$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerCancel()` */
  static readonly ReservationControllerCancelPath = '/api/reservation/{id}/cancel';

  /**
   * 예약 취소.
   *
   * 예약을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCancel$Response(params: ReservationControllerCancel$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerCancel(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 취소.
   *
   * 예약을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCancel(params: ReservationControllerCancel$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerCancel$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

}
