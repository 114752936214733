import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
  pure: true,
})
export class DistancePipe implements PipeTransform {
  transform(value: number): string {
    if (value < 1000) return `${value}m`;
    return `${(value / 1000).toFixed(1)}km`;
  }
}
