import { Component, signal } from '@angular/core';
import { BaseInputComponent } from '../common/base-input.component';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-input-password',
  standalone: true,
  imports: [FormsModule, IconComponent],
  styleUrl: '../common/input.common.scss',
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      <div class="flex gap-2 items-center input-wrapper">
        <input
          class="flex-1"
          [type]="!showPassword() ? 'password' : 'text'"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autofocus]="autofocus()"
          autocomplete="current-password"
        />
        <app-icon
          [name]="!showPassword() ? 'mdi:eye' : 'mdi:eye-off'"
          class="cursor-pointer mr-2"
          (click)="showPassword.set(!showPassword())"
        />
      </div>
      }
    </label>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputPasswordComponent,
      multi: true,
    },
  ],
})
export class InputPasswordComponent extends BaseInputComponent<string> {
  showPassword = signal<boolean>(false);
}
