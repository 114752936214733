<label>
  @if (label(); as label) {
  <span>{{ label }}</span>
  }
  <div class="input-wrapper">
    <input
      class="w-full"
      type="text"
      [(ngModel)]="value"
      placeholder="여기를 클릭하여 주소를 검색해주세요."
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      readonly="true"
      (click)="openDaumPostCode()"
    />
  </div>
</label>
