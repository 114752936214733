import { AfterViewInit, booleanAttribute, Component, computed, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { AbstractControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { StorageService } from '@api-client';
import { fromEvent, lastValueFrom } from 'rxjs';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { DataUrlPipe } from '../../pipes/data-url.pipe';
import { BaseInputComponent } from '../common/base-input.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-input-image',
  standalone: true,
  imports: [FormsModule, IconComponent, DragDropModule, DataUrlPipe],
  templateUrl: './input-image.component.html',
  styleUrl: '../common/input.common.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputImageComponent,
      multi: true,
    },
  ],
})
export class InputImageComponent extends BaseInputComponent<string[]> implements AfterViewInit {
  inputRef = viewChild.required<ElementRef<HTMLInputElement>>('input');

  accept = signal<string>('image/*');
  multiple = input<boolean, string | boolean>(true, { transform: booleanAttribute });

  files = signal<(string | File)[]>([]);
  dataUrls = computed(() => this.files().map((file) => (file instanceof File ? URL.createObjectURL(file) : file)));

  storageService = inject(StorageService);

  override writeValue(obj: any): void {
    super.writeValue(obj);
    this.files.set(obj);
  }

  ngAfterViewInit(): void {
    fromEvent(this.inputRef().nativeElement, 'change').subscribe((event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files) {
        this.files.update((prev) => [...prev, ...Array.from(files)]);
      }
    });
  }

  reorder(ev: CdkDragDrop<any, any, any>) {
    const current = ev.currentIndex;
    const previous = ev.previousIndex;

    this.files.update((prev) => {
      const next = [...prev];
      const [removed] = next.splice(previous, 1);
      next.splice(current, 0, removed);
      return next;
    });
  }

  async remove(index: number) {
    const target = this.files()[index];
    if (target) {
      if (target instanceof File) {
        URL.revokeObjectURL(this.dataUrls()[index]);
      } else {
        await lastValueFrom(this.storageService.storageControllerDeleteFile({ url: target }));
      }

      this.files.update((prev) => {
        const next = [...prev];
        next.splice(index, 1);
        return next;
      });
    }
  }

  async upload() {
    await Promise.all(
      this.files().map(async (file, i) => {
        if (file instanceof File) {
          const uploaded = await lastValueFrom(
            this.storageService.storageControllerUploadFiles({ bucketName: 'samcheonpo-images', body: { files: [file] } })
          );

          this.files.update((prev) => {
            const next = [...prev];
            next[i] = uploaded[0].url;
            return next;
          });
        }
      })
    );

    this.value.set(this.files() as string[]);
    this.onChange(this.files() as string[]);
  }
}
