import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceTime',
  standalone: true,
})
export class DistanceTimePipe implements PipeTransform {
  transform(distance?: number, type: 'drive' | 'walk' = 'drive'): string {
    if (!distance) return '알 수 없음';
    const speed = type === 'drive' ? 60_000 : 5_000; // 차량 이동 시 60km/h, 보행 시 5km/h

    const hours = Math.floor(distance / speed);
    const minutes = Math.ceil((distance % speed) / 1_000);

    if (hours === 0) {
      return `${minutes}분`;
    }

    if (minutes === 0) {
      return `${hours}시간`;
    }

    return `${hours}시간 ${minutes}분`;
  }
}
