/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attractionReviewControllerCreate } from '../fn/attraction-review/attraction-review-controller-create';
import { AttractionReviewControllerCreate$Params } from '../fn/attraction-review/attraction-review-controller-create';
import { attractionReviewControllerDelete } from '../fn/attraction-review/attraction-review-controller-delete';
import { AttractionReviewControllerDelete$Params } from '../fn/attraction-review/attraction-review-controller-delete';
import { attractionReviewControllerFindById } from '../fn/attraction-review/attraction-review-controller-find-by-id';
import { AttractionReviewControllerFindById$Params } from '../fn/attraction-review/attraction-review-controller-find-by-id';
import { attractionReviewControllerGetAttraction } from '../fn/attraction-review/attraction-review-controller-get-attraction';
import { AttractionReviewControllerGetAttraction$Params } from '../fn/attraction-review/attraction-review-controller-get-attraction';
import { attractionReviewControllerGetMy } from '../fn/attraction-review/attraction-review-controller-get-my';
import { AttractionReviewControllerGetMy$Params } from '../fn/attraction-review/attraction-review-controller-get-my';
import { attractionReviewControllerSearchOffset } from '../fn/attraction-review/attraction-review-controller-search-offset';
import { AttractionReviewControllerSearchOffset$Params } from '../fn/attraction-review/attraction-review-controller-search-offset';
import { attractionReviewControllerUpdate } from '../fn/attraction-review/attraction-review-controller-update';
import { AttractionReviewControllerUpdate$Params } from '../fn/attraction-review/attraction-review-controller-update';
import { AttractionReviewDto } from '../models/attraction-review-dto';

@Injectable({ providedIn: 'root' })
export class AttractionReviewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `attractionReviewControllerGetMy()` */
  static readonly AttractionReviewControllerGetMyPath = '/api/attraction-review';

  /**
   * 내 관광지 후기 조회.
   *
   * 로그인 한 회원의 관광지 후기를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerGetMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerGetMy$Response(params?: AttractionReviewControllerGetMy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionReviewDto>>> {
    return attractionReviewControllerGetMy(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 관광지 후기 조회.
   *
   * 로그인 한 회원의 관광지 후기를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerGetMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerGetMy(params?: AttractionReviewControllerGetMy$Params, context?: HttpContext): Observable<Array<AttractionReviewDto>> {
    return this.attractionReviewControllerGetMy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionReviewDto>>): Array<AttractionReviewDto> => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerSearchOffset()` */
  static readonly AttractionReviewControllerSearchOffsetPath = '/api/attraction-review/search/offset';

  /**
   * 관광지 후기 검색.
   *
   * 관광지 후기를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerSearchOffset$Response(params: AttractionReviewControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionReviewControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 후기 검색.
   *
   * 관광지 후기를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerSearchOffset(params: AttractionReviewControllerSearchOffset$Params, context?: HttpContext): Observable<void> {
    return this.attractionReviewControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerGetAttraction()` */
  static readonly AttractionReviewControllerGetAttractionPath = '/api/attraction-review/attraction/{attractionId}';

  /**
   * 관광지 별 후기 조회.
   *
   * 관광지 별 후기를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerGetAttraction()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerGetAttraction$Response(params?: AttractionReviewControllerGetAttraction$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionReviewDto>>> {
    return attractionReviewControllerGetAttraction(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 별 후기 조회.
   *
   * 관광지 별 후기를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerGetAttraction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerGetAttraction(params?: AttractionReviewControllerGetAttraction$Params, context?: HttpContext): Observable<Array<AttractionReviewDto>> {
    return this.attractionReviewControllerGetAttraction$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionReviewDto>>): Array<AttractionReviewDto> => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerFindById()` */
  static readonly AttractionReviewControllerFindByIdPath = '/api/attraction-review/{id}';

  /**
   * 후기 조회.
   *
   * 입력한 ID에 해당하는 관광지 후기를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerFindById$Response(params: AttractionReviewControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionReviewDto>> {
    return attractionReviewControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 후기 조회.
   *
   * 입력한 ID에 해당하는 관광지 후기를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerFindById(params: AttractionReviewControllerFindById$Params, context?: HttpContext): Observable<AttractionReviewDto> {
    return this.attractionReviewControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionReviewDto>): AttractionReviewDto => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerDelete()` */
  static readonly AttractionReviewControllerDeletePath = '/api/attraction-review/{id}';

  /**
   * 관광지 후기 삭제.
   *
   * 입력한 ID에 해당하는 관광지 후기를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerDelete$Response(params: AttractionReviewControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionReviewControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 후기 삭제.
   *
   * 입력한 ID에 해당하는 관광지 후기를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionReviewControllerDelete(params: AttractionReviewControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.attractionReviewControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerUpdate()` */
  static readonly AttractionReviewControllerUpdatePath = '/api/attraction-review/{id}';

  /**
   * 관광지 후기 수정.
   *
   * 입력한 ID에 해당하는 관광지 후기를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionReviewControllerUpdate$Response(params: AttractionReviewControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionReviewControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 후기 수정.
   *
   * 입력한 ID에 해당하는 관광지 후기를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionReviewControllerUpdate(params: AttractionReviewControllerUpdate$Params, context?: HttpContext): Observable<void> {
    return this.attractionReviewControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attractionReviewControllerCreate()` */
  static readonly AttractionReviewControllerCreatePath = '/api/attraction-review/{attractionId}';

  /**
   * 관광지 후기 등록.
   *
   * 관광지 후기를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionReviewControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionReviewControllerCreate$Response(params: AttractionReviewControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionReviewControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 후기 등록.
   *
   * 관광지 후기를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionReviewControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionReviewControllerCreate(params: AttractionReviewControllerCreate$Params, context?: HttpContext): Observable<void> {
    return this.attractionReviewControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
