/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { pointLogControllerCreatePoint } from '../fn/point-log/point-log-controller-create-point';
import { PointLogControllerCreatePoint$Params } from '../fn/point-log/point-log-controller-create-point';
import { pointLogControllerGetLogs } from '../fn/point-log/point-log-controller-get-logs';
import { PointLogControllerGetLogs$Params } from '../fn/point-log/point-log-controller-get-logs';
import { pointLogControllerGetMyLogs } from '../fn/point-log/point-log-controller-get-my-logs';
import { PointLogControllerGetMyLogs$Params } from '../fn/point-log/point-log-controller-get-my-logs';
import { pointLogControllerGetTotalPoint } from '../fn/point-log/point-log-controller-get-total-point';
import { PointLogControllerGetTotalPoint$Params } from '../fn/point-log/point-log-controller-get-total-point';
import { pointLogControllerSearchCursor } from '../fn/point-log/point-log-controller-search-cursor';
import { PointLogControllerSearchCursor$Params } from '../fn/point-log/point-log-controller-search-cursor';
import { pointLogControllerSearchOffset } from '../fn/point-log/point-log-controller-search-offset';
import { PointLogControllerSearchOffset$Params } from '../fn/point-log/point-log-controller-search-offset';
import { pointLogControllerUse } from '../fn/point-log/point-log-controller-use';
import { PointLogControllerUse$Params } from '../fn/point-log/point-log-controller-use';
import { PointLogDto } from '../models/point-log-dto';
import { TotalPointDto } from '../models/total-point-dto';

@Injectable({ providedIn: 'root' })
export class PointLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pointLogControllerGetMyLogs()` */
  static readonly PointLogControllerGetMyLogsPath = '/api/point-log';

  /**
   * 내 포인트 내역 조회.
   *
   * 현재 로그인한 회원의 포인트 적립 내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerGetMyLogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetMyLogs$Response(params?: PointLogControllerGetMyLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PointLogDto>>> {
    return pointLogControllerGetMyLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 포인트 내역 조회.
   *
   * 현재 로그인한 회원의 포인트 적립 내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerGetMyLogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetMyLogs(params?: PointLogControllerGetMyLogs$Params, context?: HttpContext): Observable<Array<PointLogDto>> {
    return this.pointLogControllerGetMyLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PointLogDto>>): Array<PointLogDto> => r.body)
    );
  }

  /** Path part for operation `pointLogControllerGetTotalPoint()` */
  static readonly PointLogControllerGetTotalPointPath = '/api/point-log/total';

  /**
   * 내 포인트 조회.
   *
   * 현재 로그인한 회원의 포인트 합계를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerGetTotalPoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetTotalPoint$Response(params?: PointLogControllerGetTotalPoint$Params, context?: HttpContext): Observable<StrictHttpResponse<TotalPointDto>> {
    return pointLogControllerGetTotalPoint(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 포인트 조회.
   *
   * 현재 로그인한 회원의 포인트 합계를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerGetTotalPoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetTotalPoint(params?: PointLogControllerGetTotalPoint$Params, context?: HttpContext): Observable<TotalPointDto> {
    return this.pointLogControllerGetTotalPoint$Response(params, context).pipe(
      map((r: StrictHttpResponse<TotalPointDto>): TotalPointDto => r.body)
    );
  }

  /** Path part for operation `pointLogControllerSearchOffset()` */
  static readonly PointLogControllerSearchOffsetPath = '/api/point-log/search/offset';

  /**
   * 포인트 내역 검색.
   *
   * 포인트 적립 내역을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerSearchOffset$Response(params: PointLogControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<PointLogDto>;
}>> {
    return pointLogControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 포인트 내역 검색.
   *
   * 포인트 적립 내역을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerSearchOffset(params: PointLogControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<PointLogDto>;
}> {
    return this.pointLogControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<PointLogDto>;
}>): OffsetPaginationDto & {
'items'?: Array<PointLogDto>;
} => r.body)
    );
  }

  /** Path part for operation `pointLogControllerSearchCursor()` */
  static readonly PointLogControllerSearchCursorPath = '/api/point-log/search/cursor';

  /**
   * 포인트 내역 검색.
   *
   * 포인트 적립 내역을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerSearchCursor$Response(params: PointLogControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<PointLogDto>;
}>> {
    return pointLogControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 포인트 내역 검색.
   *
   * 포인트 적립 내역을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerSearchCursor(params: PointLogControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<PointLogDto>;
}> {
    return this.pointLogControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<PointLogDto>;
}>): CursorPaginationDto & {
'items'?: Array<PointLogDto>;
} => r.body)
    );
  }

  /** Path part for operation `pointLogControllerGetLogs()` */
  static readonly PointLogControllerGetLogsPath = '/api/point-log/{memberId}';

  /**
   * 특정 회원의 포인트 내역 조회.
   *
   * 특정 회원의 포인트 적립 내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerGetLogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetLogs$Response(params?: PointLogControllerGetLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PointLogDto>>> {
    return pointLogControllerGetLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * 특정 회원의 포인트 내역 조회.
   *
   * 특정 회원의 포인트 적립 내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerGetLogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerGetLogs(params?: PointLogControllerGetLogs$Params, context?: HttpContext): Observable<Array<PointLogDto>> {
    return this.pointLogControllerGetLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PointLogDto>>): Array<PointLogDto> => r.body)
    );
  }

  /** Path part for operation `pointLogControllerUse()` */
  static readonly PointLogControllerUsePath = '/api/point-log/use';

  /**
   * 포인트 사용.
   *
   * 포인트를 사용합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerUse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pointLogControllerUse$Response(params: PointLogControllerUse$Params, context?: HttpContext): Observable<StrictHttpResponse<PointLogDto>> {
    return pointLogControllerUse(this.http, this.rootUrl, params, context);
  }

  /**
   * 포인트 사용.
   *
   * 포인트를 사용합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerUse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pointLogControllerUse(params: PointLogControllerUse$Params, context?: HttpContext): Observable<PointLogDto> {
    return this.pointLogControllerUse$Response(params, context).pipe(
      map((r: StrictHttpResponse<PointLogDto>): PointLogDto => r.body)
    );
  }

  /** Path part for operation `pointLogControllerCreatePoint()` */
  static readonly PointLogControllerCreatePointPath = '/api/point-log/{memberId}/add';

  /**
   * 포인트 적립.
   *
   * 특정 회원에게 포인트를 적립합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pointLogControllerCreatePoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerCreatePoint$Response(params?: PointLogControllerCreatePoint$Params, context?: HttpContext): Observable<StrictHttpResponse<PointLogDto>> {
    return pointLogControllerCreatePoint(this.http, this.rootUrl, params, context);
  }

  /**
   * 포인트 적립.
   *
   * 특정 회원에게 포인트를 적립합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pointLogControllerCreatePoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pointLogControllerCreatePoint(params?: PointLogControllerCreatePoint$Params, context?: HttpContext): Observable<PointLogDto> {
    return this.pointLogControllerCreatePoint$Response(params, context).pipe(
      map((r: StrictHttpResponse<PointLogDto>): PointLogDto => r.body)
    );
  }

}
