import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { CouponDto } from '@api-client';

@Component({
  selector: 'app-coupon-item',
  standalone: true,
  imports: [DecimalPipe, DatePipe, NgClass],
  templateUrl: './coupon-item.component.html',
  styleUrl: './coupon-item.component.scss',
})
export class CouponItemComponent {
  use = output<void>();

  coupon = input.required<CouponDto>();
}
