import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bold',
  standalone: true,
  pure: false,
})
export class BoldPipe implements PipeTransform {
  transform(value: any, target?: string) {
    if (!value || !target) {
      return value;
    }
    const re = new RegExp(target, 'gi');
    const result = value.replace(re, '<b>$&</b>');
    return result;
  }
}
