import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, Injectable, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownComponent } from 'ngx-markdown';

export type AlertProps = {
  title?: string;
  message: string;
  // inputs?: AlertInput[];
};

export type AlertInput = {
  label: string;
  key: string;
  type?: string;
  default?: any;
  required?: boolean;
};

export type AlertResult = {
  role: 'confirm' | 'cancel';
  data: Record<string, any>;
};

@Injectable({ providedIn: 'root' })
export class AlertService {
  currentOpenAlert = signal<DialogRef<any, any> | null>(null);

  constructor(private readonly dialog: Dialog) {}

  public open(data?: AlertProps): DialogRef<AlertResult, AlertComponent> {
    const dialogRef = this.dialog.open<AlertResult, unknown, AlertComponent>(AlertComponent, {
      data,
      hasBackdrop: true,
      disableClose: true,
    });

    this.currentOpenAlert.set(dialogRef);

    dialogRef.closed.subscribe(() => this.currentOpenAlert.set(null));

    return dialogRef;
  }

  close = () => this.dialog.closeAll();
}

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MarkdownComponent],
  template: `
    <div class="flex flex-col overflow-hidden divide-y rounded-md bg-white divide-neutral-300 w-96">
      @if (data.title) {
      <section class="p-4 select-none">
        <h4>{{ data.title }}</h4>
      </section>
      }

      <section id="alert-content" class="flex flex-col flex-1 gap-4 p-4">
        <markdown>
          {{ data.message }}
        </markdown>
        <!-- @if(data.inputs && form) {
        <form [formGroup]="form" class="flex flex-col gap-2" (ngSubmit)="confirm()">
          @for (input of data.inputs; track input.key) { @switch (input.type) { @case('text') {
          <app-input-text [label]="input.label" variant="block" [formControlName]="input.key" />
          } @case('number') {
          <app-input-number [label]="input.label" variant="block" [formControlName]="input.key" />
          } } }
        </form>
        } -->
      </section>

      <section class="flex w-full select-none">
        <button
          class="flex-1 p-4 font-bold transition-colors text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900"
          (click)="close()"
        >
          취소
        </button>
        <button
          class="flex-1 p-4 font-bold text-red-500 transition-colors hover:bg-red-500 hover:text-white disabled:bg-neutral-900 disabled:text-neutral-800 disabled:cursor-not-allowed"
          (click)="confirm()"
          [disabled]="form?.invalid"
        >
          확인
        </button>
      </section>
    </div>
  `,
})
class AlertComponent {
  confirmButtonClasses = signal<string>('text-danger-500 hover:bg-danger-500 hover:text-white');

  form: FormGroup | null = null;

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.confirm();
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      this.close();
    }
  }

  data = inject<AlertProps>(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  constructor() {
    // if (this.data.inputs) {
    //     for (const input of this.data.inputs) {
    //       this.form = new FormGroup({
    //         [input.key]: new FormControl<string | null>(input.default ?? null, {
    //           validators: input.required ? [Validators.required] : [],
    //         }),
    //       });
    //     }
    //   }
  }

  close() {
    this.dialogRef.close({
      role: 'cancel',
    });
  }

  confirm() {
    if (this.form?.invalid) return;
    this.dialogRef.close({
      role: 'confirm',
      data: this.form?.getRawValue(),
    });
  }
}
