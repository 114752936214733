import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string',
  standalone: true,
  pure: false,
})
export class StringPipe implements PipeTransform {
  transform(value: any) {
    return value.toString();
  }
}
