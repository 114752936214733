@if (data()) {
<apx-chart
  [series]="series()"
  [chart]="chart()"
  [theme]="theme"
  [tooltip]="tooltip"
  [colors]="colors"
  [grid]="grid"
  [yaxis]="yaxis"
/>
}
