import { Component, input, numberAttribute } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';

@Component({
  selector: 'app-input-textarea',
  standalone: true,
  imports: [FormsModule],
  styleUrl: '../common/input.common.scss',
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <textarea
          class="resize-none"
          [rows]="rows()"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
        ></textarea>
      </div>
    </label>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextareaComponent,
      multi: true,
    },
  ],
})
export class InputTextareaComponent extends BaseInputComponent<string> {
  rows = input<number, string | number>(4, { transform: numberAttribute });
}
