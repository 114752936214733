@if (!loaded()) {
<div class="absolute z-10 bg-white size-full">
  <ion-skeleton-text [animated]="true" class="size-full" />
</div>
} @if (error()) {
<div class="absolute z-10 flex items-center justify-center transition-opacity opacity-0 size-full bg-neutral-100">
  <ion-icon name="image" class="text-neutral-500/20 size-8" />
</div>
}
<img [src]="src()" class="object-cover size-full" (load)="onLoad()" (error)="error.set(true)" loading="lazy" />
