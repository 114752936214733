@if (coupon(); as coupon) {
<div class="flex flex-col gap-2 overflow-hidden border rounded-md shadow-lg border-neutral-200 size-full">
  <div class="flex flex-col gap-4 p-4">
    <div class="flex items-center justify-between">
      <h2>{{ coupon.name }}</h2>
      @switch (coupon.type) { @case ('FIXED') {
      <h3>{{ $any(coupon.detail).amount | number }}원 할인</h3>
      } @case ('RATIONAL') {
      <div class="flex flex-col gap-1">
        <h3>{{ $any(coupon.detail).ratio }}% 할인</h3>
        <p>최대 {{ $any(coupon.detail).max }}원 할인</p>
      </div>
      } @case ('REDEEM') {
      <h3>{{ $any(coupon.detail).product }} 증정</h3>
      } }
    </div>
  </div>
</div>
}
