import { Component, inject, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';

@Component({
  selector: 'app-input-text',
  standalone: true,
  imports: [FormsModule],
  styleUrl: '../common/input.common.scss',
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <input
          class="w-full"
          type="text"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
          [autofocus]="autofocus()"
        />
        @if (suffix()) {
        <span class="min-w-fit">{{ suffix() }}</span>
        }
      </div>
    </label>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextComponent,
      multi: true,
    },
  ],
})
export class InputTextComponent extends BaseInputComponent<string> {
  suffix = input<string>();
}
