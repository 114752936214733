/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { oauthControllerAppleLogin } from '../fn/o-auth/oauth-controller-apple-login';
import { OauthControllerAppleLogin$Params } from '../fn/o-auth/oauth-controller-apple-login';
import { oauthControllerKakaoLogin } from '../fn/o-auth/oauth-controller-kakao-login';
import { OauthControllerKakaoLogin$Params } from '../fn/o-auth/oauth-controller-kakao-login';
import { TokensDto } from '../models/tokens-dto';

@Injectable({ providedIn: 'root' })
export class OAuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `oauthControllerKakaoLogin()` */
  static readonly OauthControllerKakaoLoginPath = '/api/oauth/kakao';

  /**
   * 카카오 소셜 로그인.
   *
   * 카카오 소셜 로그인을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerKakaoLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerKakaoLogin$Response(params?: OauthControllerKakaoLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<TokensDto>> {
    return oauthControllerKakaoLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 카카오 소셜 로그인.
   *
   * 카카오 소셜 로그인을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerKakaoLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerKakaoLogin(params?: OauthControllerKakaoLogin$Params, context?: HttpContext): Observable<TokensDto> {
    return this.oauthControllerKakaoLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokensDto>): TokensDto => r.body)
    );
  }

  /** Path part for operation `oauthControllerAppleLogin()` */
  static readonly OauthControllerAppleLoginPath = '/api/oauth/apple';

  /**
   * 애플 소셜 로그인.
   *
   * 애플 소셜 로그인을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerAppleLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerAppleLogin$Response(params?: OauthControllerAppleLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<TokensDto>> {
    return oauthControllerAppleLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 애플 소셜 로그인.
   *
   * 애플 소셜 로그인을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerAppleLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerAppleLogin(params?: OauthControllerAppleLogin$Params, context?: HttpContext): Observable<TokensDto> {
    return this.oauthControllerAppleLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokensDto>): TokensDto => r.body)
    );
  }

}
