import { Component, HostListener, contentChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuItemComponent } from '../context-menu-item/context-menu-item.component';

@Component({
  selector: 'app-context-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss',
})
export class ContextMenuComponent {
  items = contentChildren(ContextMenuItemComponent);
}
