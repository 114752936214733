/* eslint-disable @typescript-eslint/no-empty-function */
import { booleanAttribute, Component, computed, ElementRef, inject, Injector, input, model, OnInit, signal } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NgControl,
  NgModel,
  Validators,
} from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';

@Component({
  standalone: true,
  template: '',
})
export class BaseInputComponent<T> implements ControlValueAccessor, OnInit {
  value = model<T>();
  value$ = toObservable(this.value);
  label = input<string | undefined>(undefined);

  placeholder = input<string>('');

  _readonly = input<boolean, string | boolean>(false, { alias: 'readonly', transform: booleanAttribute });
  $readonly = signal<boolean>(false);
  readonly = computed(() => this._readonly() || this.$readonly());

  _disabled = input<boolean, string | boolean>(false, { alias: 'disabled', transform: booleanAttribute });
  $disabled = signal<boolean>(false);
  disabled = computed(() => this._disabled() || this.$disabled());

  _required = input<boolean, string | boolean>(false, { alias: 'required', transform: booleanAttribute });
  $required = signal<boolean>(false);
  required = computed(() => this._required() || this.$required());

  autofocus = input<boolean, string | boolean>(false, { transform: booleanAttribute });
  debounce = input<number>(0);
  autocomplete = input<string>();
  spellcheck = input<boolean, string | boolean>(false, { transform: booleanAttribute });

  injector = inject(Injector);
  formControl: FormControl | null = null;
  elementRef = inject(ElementRef<HTMLElement>);

  constructor() {
    this.value$.pipe(skip(1), debounceTime(this.debounce()), distinctUntilChanged()).subscribe({
      next: (value) => {
        this.onChange(value as T);
        this.onTouched();
      },
    });
  }

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });

    if (ngControl instanceof NgModel) {
      this.formControl = ngControl.control as FormControl;
    } else if (ngControl instanceof FormControlDirective) {
      this.formControl = ngControl.control;
    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer, null)?.control as FormGroup;
      this.formControl = container.controls[ngControl.name!] as FormControl;
    }

    if (this.formControl?.hasValidator(Validators.required)) {
      this.$required.set(true);
      this.elementRef.nativeElement.setAttribute('data-required', 'true');
    }
  }

  onChange = (value: T) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.$disabled.set(isDisabled);
  }
}
