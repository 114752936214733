/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VersionDto } from '../../models/version-dto';

export interface VersionControllerGetVersion$Params {
  version: string;
}

export function versionControllerGetVersion(http: HttpClient, rootUrl: string, params: VersionControllerGetVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<VersionDto>> {
  const rb = new RequestBuilder(rootUrl, versionControllerGetVersion.PATH, 'get');
  if (params) {
    rb.query('version', params.version, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<VersionDto>;
    })
  );
}

versionControllerGetVersion.PATH = '/api/version/version/{version}';
