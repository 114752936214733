import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Color } from '../type';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class BadgeComponent {
  color = input<Color>('primary');
}
