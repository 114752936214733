/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { settingControllerGet } from '../fn/setting/setting-controller-get';
import { SettingControllerGet$Params } from '../fn/setting/setting-controller-get';
import { settingControllerUpdate } from '../fn/setting/setting-controller-update';
import { SettingControllerUpdate$Params } from '../fn/setting/setting-controller-update';
import { SettingDto } from '../models/setting-dto';

@Injectable({ providedIn: 'root' })
export class SettingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `settingControllerGet()` */
  static readonly SettingControllerGetPath = '/api/setting';

  /**
   * 설정 조회.
   *
   * 설정을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingControllerGet$Response(params?: SettingControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SettingDto>>> {
    return settingControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * 설정 조회.
   *
   * 설정을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingControllerGet(params?: SettingControllerGet$Params, context?: HttpContext): Observable<Array<SettingDto>> {
    return this.settingControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingDto>>): Array<SettingDto> => r.body)
    );
  }

  /** Path part for operation `settingControllerUpdate()` */
  static readonly SettingControllerUpdatePath = '/api/setting';

  /**
   * 설정 수정.
   *
   * 설정을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingControllerUpdate$Response(params: SettingControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SettingDto>>> {
    return settingControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 설정 수정.
   *
   * 설정을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingControllerUpdate(params: SettingControllerUpdate$Params, context?: HttpContext): Observable<Array<SettingDto>> {
    return this.settingControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingDto>>): Array<SettingDto> => r.body)
    );
  }

}
