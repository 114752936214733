<label>
  @if (label(); as label) {
  <span>{{ label }}</span>
  }
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="reorder($event)"
    class="flex max-w-full gap-1 p-2 overflow-x-auto snap-x"
  >
    @for (file of files(); track file) {
    <div class="relative rounded-md cursor-move select-none size-48 snap-center shrink-0" cdkDrag>
      <div
        (click)="remove($index)"
        class="absolute top-0 right-0 flex items-center justify-center p-2 transition-colors bg-red-500 rounded-full cursor-pointer size-10 hover:bg-red-400 active:bg-red-300"
      >
        <app-icon name="mdi:delete" class="text-white" />
      </div>
      <img [src]="file | dataUrl" class="object-cover size-full" alt="Image {{ $index + 1 }}" />
    </div>
    }
    <div
      class="flex items-center justify-center border rounded-md cursor-pointer snap-center shrink-0 size-48 border-neutral-200 bg-neutral-50 hover:bg-neutral-100"
      (click)="input.click()"
    >
      <app-icon name="mdi:plus" size="xl" />
    </div>
  </div>
</label>

<input #input type="file" hidden [accept]="accept()" [multiple]="multiple()" />
