import { Component, computed, input, numberAttribute } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexGrid, ApexTheme, ApexTooltip, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';

export type LineChartData = {
  [key: string]: number;
};

@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [NgApexchartsModule],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
})
export class LineChartComponent {
  data = input<LineChartData | null>();
  height = input<number, string | number>(200, { transform: numberAttribute });

  series = computed<ApexAxisChartSeries>(() => {
    return [
      {
        data: Object.entries(this.data() || {}).map(([key, value]) => ({
          x: key,
          y: value,
        })),
      },
    ];
  });

  chart = computed<ApexChart>(() => ({
    type: 'line',
    height: `${this.height()}px`,
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    fontFamily: 'SUIT',
    background: 'transparent',
  }));

  theme: ApexTheme = {
    mode: 'dark',
  };

  tooltip: ApexTooltip = {
    y: { title: { formatter: () => '' } },
  };

  grid: ApexGrid = {
    show: true,
    borderColor: '#e5e5e5',
  };

  yaxis: ApexYAxis = {
    tickAmount: 1,
  };

  colors: string[] = ['#00203E'];
}
