import { Pipe, PipeTransform } from '@angular/core';
import { CouponType } from '@common';

@Pipe({
  name: 'couponType',
  standalone: true,
})
export class CouponTypePipe implements PipeTransform {
  transform(value: string) {
    const result = CouponType[value as keyof typeof CouponType];
    if (result) return result;

    return '';
  }
}
