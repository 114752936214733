<button
  [disabled]="disabled()"
  class="flex items-center justify-between w-full gap-2 p-2 transition-colors rounded-md min-w-32 hover:bg-neutral-200 disabled:bg-neutral-600 disabled:cursor-not-allowed text-neutral-900 disabled:text-neutral-400"
>
  <div class="flex items-center gap-2" [class]="colorClass()">
    @if (icon(); as icon) {
    <app-icon [name]="icon" size="md" class="text-inherit" />
    }
    <div class="font-bold" [class]="colorClass()">
      <ng-content />
    </div>
  </div>
  @if (suffix(); as suffix) {
  <span class="text-sm">{{ suffix }}</span>
  }
</button>
