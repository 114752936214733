/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SetSettingDto } from '../../models/set-setting-dto';
import { SettingDto } from '../../models/setting-dto';

export interface SettingControllerUpdate$Params {
      body: Array<SetSettingDto>
}

export function settingControllerUpdate(http: HttpClient, rootUrl: string, params: SettingControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SettingDto>>> {
  const rb = new RequestBuilder(rootUrl, settingControllerUpdate.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SettingDto>>;
    })
  );
}

settingControllerUpdate.PATH = '/api/setting';
