/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TokensDto } from '../../models/tokens-dto';

export interface OauthControllerKakaoLogin$Params {

/**
 * 인가 코드
 */
  code?: string;

/**
 * 상태
 */
  token?: string;
}

export function oauthControllerKakaoLogin(http: HttpClient, rootUrl: string, params?: OauthControllerKakaoLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<TokensDto>> {
  const rb = new RequestBuilder(rootUrl, oauthControllerKakaoLogin.PATH, 'post');
  if (params) {
    rb.query('code', params.code, {});
    rb.query('token', params.token, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TokensDto>;
    })
  );
}

oauthControllerKakaoLogin.PATH = '/api/oauth/kakao';
