import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, PLATFORM_ID, Renderer2, computed, inject, signal, viewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { DaumPostCodeResponse } from './daum-post-code.type';
import { BaseInputComponent } from '../common/base-input.component';
import { ButtonComponent } from '../button/button.component';

export type Address = {
  address: string;
  detailAddress: string;
  postcode: string;
};

@Component({
  selector: 'app-input-address',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent],
  templateUrl: './input-address.component.html',
  styleUrl: '../common/input.common.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputAddressComponent,
      multi: true,
    },
  ],
})
export class InputAddressComponent extends BaseInputComponent<string> implements ControlValueAccessor, OnInit {
  renderer = inject(Renderer2, { optional: false });
  platformId = inject(PLATFORM_ID, { optional: false });

  roadAddress = viewChild<ElementRef<HTMLInputElement>>('roadAddress');

  constructor() {
    super();
  }

  override ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    if (typeof document !== 'undefined') {
      this.renderer.appendChild(document.body, script);
    }

    super.ngOnInit();
  }

  override writeValue(address: string): void {
    this.value.set(address);
  }

  openDaumPostCode(): void {
    new (window as any).daum.Postcode({
      width: 500,
      height: 600,
      oncomplete: (data: DaumPostCodeResponse) => {
        this.value.set(data.roadAddress);
      },
    }).open({
      left: window.screen.width / 2 - 500 / 2,
      top: window.screen.height / 2 - 600 / 2,
      popupKey: 'daum_postcode',
    });
  }
}
