import { Component, ElementRef, HostBinding, booleanAttribute, computed, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { Color, Size } from '../type';
export type ContextMenuItemShortkey = { label: string; key: string };
@Component({
  selector: 'app-context-menu-item',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './context-menu-item.component.html',
  styleUrl: './context-menu-item.component.scss',
})
export class ContextMenuItemComponent {
  @HostBinding('class') class = 'w-full';

  icon = input<string>();
  color = input<Color>('neutral');
  size = input<Size>('sm');
  suffix = input<string | boolean>();
  disabled = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });

  colorClass = computed(() => {
    switch (this.color()) {
      case 'primary':
        return 'text-primary-500';
      case 'danger':
        return 'text-red-500';
      case 'neutral':
        return 'text-neutral-900';
      case 'success':
        return 'text-green-500';
      case 'warning':
        return 'text-yellow-500';
      case 'black':
        return 'text-neutral-900';
      case 'info':
        return 'text-blue-500';
      case 'white':
        return 'text-white';
    }
  });

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
